<template>
    <div class="p-fluid formgrid grid form">
        <div class="field col-12">
            <label for="">Nome</label>
            <InputText type="text" v-model="state.name" />
        </div>

        <div class="field col-12">
            <label for="">Callback URL</label>
            <InputText type="text" v-model="state.callbackUrl" />
        </div>

        <div class="field col-12">
            <Button :loading="state.loading.tryingToRegister" label="Registrar Cliente" icon="pi pi-check" iconPos="right"
                @click="tryToRegisterClient" />
        </div>
    </div>

    <Dialog v-model:visible="state.tokenModal" :style="{ width: '550px' }" modal :header="'Atenção!'" class="text-center"
        @update:visible="handleClose">

        <Message severity="warn" sticky :closable="false">
            Token de vizualização única, por favor, guarde o token em algum lugar seguro, pois não será possível ver o
            token novamente.
        </Message>

        <div class="text-center font-bold flex justify-content-center">
            <h5 class="border-1 border-round-3xl py-2 px-4 w-max">{{ state.token }}</h5>
        </div>

    </Dialog>
</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Message from 'primevue/message'
import services from '../../services'
import { reactive, } from 'vue'
import { useStore } from 'vuex'
import useNotificationToast from '../../composables/useNotificationToast'
import useVendors from '../../composables/useVendors'

export default {

    components: { InputText, Button, Dialog, Message },

    setup() {

        const state = reactive({
            name: null,
            callbackUrl: null,
            selectedVendors: [],
            loading: {
                tryingToRegister: false,
                vendors: false
            },
            tokenModal: false,
            token: null,
        })

        const store = useStore()
        const { success, error } = useNotificationToast()

        const { stateVendors, loadVendors } = useVendors()

        loadVendors()

        async function tryToRegisterClient() {

            const vendorsId = []

            state.selectedVendors.map((item) => {
                vendorsId.push(item.id)
            })

            state.loading.tryingToRegister = true

            if (!state.name) {
                state.loading.tryingToRegister = false
                return error('Verifique todos os campos!')
            }

            const token = store.getters.getToken

            await services.clients.register({
                name: state.name,
                callbackUrl: state.callbackUrl,
                token
            }).then(({ data }) => {
                success('Cliente registrado com sucesso!')
                state.name = null
                state.callbackUrl = null
                state.token = data.token
                state.tokenModal = true
            })
                .catch(() => {
                    error('Não foi possível registrar o cliente.')
                })
                .finally(() => { state.loading.tryingToRegister = false })

        }

        function handleClose() {
            state.token = null
        }


        return {
            state,
            stateVendors,
            tryToRegisterClient,
            handleClose
        }
    }
}

</script>

<style lang="scss" scoped>
.form {
    max-width: 500px;
    margin: 0 auto;
}
</style>